import './style.css'
import * as THREE from 'three'
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js'

const gltfloader = new GLTFLoader()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// Objects
let mixer;
let mixer2;
let world;
let moon;
let Stern;
var Sternarray=[];

gltfloader.load('/models/erde.glb', function(gltf){
    world = gltf.scene;
    scene.add(world);
    mixer = new THREE.AnimationMixer(world)
    const clips = gltf.animations
    const clip = THREE.AnimationClip.findByName(clips, 'idleworldrotation')
    console.log(clip)
    const action = mixer.clipAction(clip)
    action.play()

});

gltfloader.load('/models/moon3.glb', function(gltf2){
    moon = gltf2.scene;
    mixer2 = new THREE.AnimationMixer(moon)
    const clips2 = gltf2.animations
    console.log(clips2)
    const clip2 = THREE.AnimationClip.findByName(clips2, 'idlemoon')
    console.log(clip2)
    const action2 = mixer2.clipAction(clip2)
    action2.play();
    moon.scale.set(.4, .4, .4);
    moon.position.x = -1.5;
    moon.position.z = 5;

})

function Sterne(){
    const geometry = new THREE.SphereGeometry(.24,14,14);
    const material = new THREE.MeshToonMaterial({color: 0xffffff})
    Stern = new THREE.Mesh(geometry,material);

    const [x,y,z] = Array(3).fill().map(() => THREE.MathUtils.randFloatSpread(100));

    Stern.position.set(x,y,z);
    scene.add(Stern)
    Sternarray.push(Stern)
}

Array(200).fill().forEach(Sterne)

// Lights

const pointLight = new THREE.DirectionalLight(0xffffff, 3)
pointLight.position.x = 2
pointLight.position.y = 3
pointLight.position.z = 4
scene.add(pointLight)

const pointLight2 = new THREE.DirectionalLight(0x6e6e6e, 3)
pointLight2.position.x = 1.8
pointLight2.position.y = -3
pointLight2.position.z = -0.02
pointLight2.intensity = .85
scene.add(pointLight2)



const pointLight3 = new THREE.PointLight(0x8A2BE2, 1)
pointLight3.position.x = -3
pointLight3.position.y = 2.51
pointLight3.position.z = 1.47
pointLight3.intensity = 5
scene.add(pointLight3)



/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 0
camera.position.y = 0
camera.position.z = 2
scene.add(camera)


/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */

const clock = new THREE.Clock()

var a=0;

const updateUniverse = (event) => {
    world.position.z = window.scrollY * -.01;
    //console.log(world.position.z) 
    for (var i = 0; i < Sternarray.length; i++) {

        var star = Sternarray[i];

        if(a > world.position.z){

            star.position.z -= i/200;

        }else{

            star.position.z += i/200;
        
        }
        // if the particle is too close move it to the back
        if (star.position.z < -100) star.position.z += 200;
        if (star.position.z > 0) star.position.z -= 100;
      }

    a = world.position.z;
    
    if(a<-2){

        scene.add(moon)

    }
    moon.position.z = 10 + window.scrollY * -.01;
    //console.log(moon.position.z)

}
window.addEventListener('scroll', updateUniverse)


const tick = () =>
{
    const delta = clock.getDelta()
    // Update objects
    if(mixer)
        mixer.update(delta*0.3);
    
    if(mixer2)
        mixer2.update(delta*.5);
        

 
    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()

const observer = new IntersectionObserver((entries)=>{
    entries.forEach((entry)=>{
        if(entry.isIntersecting){
           entry.target.classList.add('show'); 
        }else{
            entry.target.classList.remove('show');
        }
    });
});

const hiddenElements = document.querySelectorAll('.container2');
hiddenElements.forEach((el)=> observer.observe(el));

const toggleButton = document.getElementsByClassName('toggle-button')[0]
const navlinks = document.getElementsByClassName('nav-links')[0]

toggleButton.addEventListener('click', ()=>{
    navlinks.classList.toggle('active')
})

